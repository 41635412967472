import React, { useState } from 'react';
import SideMenuOurCompanies from './SideMenuOurCompanies';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';
import SideMenuDownloadApp from './SideMenuDownloadApp';

// const SideMenuQuery = gqlLoader('./SideMenu.graphql');

const SideMenu = (props) => {
  const [activeModal, setActiveModal] = useState('');
  const changeActiveModal = (newModal) => {
    setActiveModal(newModal);
  };

  return (
    <>
      <div className="right_sticky_options_wrapper">
        <ul className="right_option_list">
          {props?.fields?.data?.side_menu?.Items?.targetItems?.map((item, itemIndex) => {
            const isAnchor = item?.CTA?.linkType === 'anchor';
            const isExternal = item?.CTA?.linkType === 'external';
            return (
              <li key={itemIndex} className="rightNavAbcd-logo">
                <a
                  href={isAnchor ? '#' : item?.CTA?.url}
                  target={isExternal ? item?.CTA?.target : undefined}
                  onClick={
                    isAnchor
                      ? (e) => {
                          e.preventDefault();
                        }
                      : undefined
                  }
                  data-bs-toggle={isAnchor ? 'modal' : undefined}
                  data-bs-target={isAnchor ? item?.CTA?.url : undefined}
                  className="downloadLink"
                >
                  <img
                    src={item?.Icon?.src}
                    alt={item?.Label?.value}
                    className="mob_app_right downloadImage"
                    loading="lazy"
                  />
                  {item?.IconSecond?.src && (
                    <img
                      src={item?.IconSecond?.src}
                      alt={item?.IconSecond?.alt}
                      className="mob_app_right hover-img"
                      loading="lazy"
                    />
                  )}
                  <Text field={item?.Label} editable />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      {/* <SideMenuOurCompanies activeModal={activeModal}></SideMenuOurCompanies> */}
      {/* <SideMenuDownloadApp /> */}
    </>
  );
};

export default SideMenu;
