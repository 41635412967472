import React, { useEffect, useState } from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

const Tools = (props) => {
  const { isToolsShow, onToolsLoad, navStickyClass, toolsData } = props;
  // const isToolsShow = props?.isToolsShow;
  const tools = toolsData?.targetItem;
  // const onToolsLoad = props?.onToolsLoad;

  useEffect(() => {
    if (tools) {
      onToolsLoad(tools);
    }
  }, [tools]);

  const [activeSecondaryTab, setActiveSecondaryTab] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const [activeTab, setActiveTab] = useState('abcmoney_block');
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [calculators, setCalculators] = useState(false);

  useEffect(() => {
    setActiveSecondaryTab('');
  }, [isToolsShow, activeTab]);

  const changeActiveSecondaryTab = (newTab) => {
    if (initialRender) {
      setInitialRender(false);
    }
    setActiveThirdTab('');
    setActiveSecondaryTab(newTab);
  };

  const [activeThirdTab, setActiveThirdTab] = useState('');

  const changeActiveThirdTab = (newTab) => {
    setActiveThirdTab(newTab);
  };

  useEffect(() => {
    const swiper1 = new Swiper('.nav_related_reads_slider', {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });

    const swiper2 = new Swiper('.sip_calc_nav_slider', {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
  }, []);

  const handelTabChange = (id, index) => {
    setActiveTab(id);
    setActiveTabIndex(index);
  };

  return (
    <div
      className={`resources_megamenu_wrapper ${navStickyClass} ${
        isToolsShow ? 'megamenu_open' : ''
      }`}
      id="resources_wrapper_id"
    >
      <div className="loan_megamenu_container">
        <div className="loan_options_wrapper">
          <div className="loan_lists_wrapper">
            <ul className="loan_lists">
              {tools?.first &&
                tools?.first.map((first, index) => (
                  <li
                    key={index}
                    id={first?.CssId?.value}
                    className={
                      activeTab === first?.CssId?.value || activeTabIndex === index
                        ? 'list_hovered'
                        : ''
                    }
                  >
                    <a
                      id="menuTitle"
                      href={first?.MenuLink?.url}
                      onMouseEnter={() => handelTabChange(first?.CssId?.value, index)}
                      target={first?.MenuLink?.target}
                    >
                      <Text field={first?.MenuTitle} /> <em className="right_arrow" />
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <div
            className="loan_plans_Wrapper"
            id={`${activeTab === 'abcmoney_block' ? 'abcmoney_block' : 'tools_calculators_block'}`}
          >
            <div className="terms_list_wrapper">
              {tools?.first &&
                tools?.first.map((first, index) =>
                  Array.isArray(first?.second[index]?.third) &&
                  first?.second[index]?.third.length === 0 ? (
                    <div
                      className={`terms_list_grid ${
                        activeTab === first.CssId.value || activeTabIndex === 0 ? '' : 'd-none'
                      }`}
                      key={index}
                    >
                      {first?.second.map((second, index) => (
                        <a
                          href={second?.MenuLink?.url}
                          key={index}
                          target={second?.MenuLink?.target}
                        >
                          <div className="terms_wrapper">
                            <Image field={second?.Icon} editable loading="lazy" />
                            <span className="term-card-heading">{second?.MenuTitle?.value}</span>
                            <p>{second?.MenuSubTitle?.value}</p>
                          </div>
                        </a>
                      ))}
                    </div>
                  ) : (
                    <React.Fragment key={index}>
                      {first?.second &&
                        activeTab === first.CssId.value &&
                        first?.second.map((second, index) => (
                          <React.Fragment key={index}>
                            {index === 0 ? (
                              <div className="wrapper_title_text align-items-center d-flex justify-content-between me-3 mt-0">
                                <span className="wrapper_title_text">
                                  {second?.MenuTitle?.value}
                                </span>
                                <a
                                  href={second.MenuLink.url}
                                  target={second.MenuLink.target}
                                  className="link-view-all"
                                >
                                  {props.t('ViewAll')}
                                </a>
                              </div>
                            ) : (
                              <span className="wrapper_title_text">{second?.MenuTitle?.value}</span>
                            )}
                            <div
                              className={`terms_list_grid ${
                                activeTab !== first.CssId.value ? 'd-none' : ''
                              }`}
                            >
                              {second?.third.map((third, index) => (
                                <a
                                  href={third?.MenuLink?.url}
                                  key={index}
                                  target={third?.MenuLink?.target}
                                >
                                  <div className="terms_wrapper">
                                    <Image field={third?.Icon} loading="lazy" />
                                    <span className="term-card-heading">
                                      {third?.MenuTitle?.value}
                                    </span>
                                    <p>{third?.MenuSubTitle?.value}</p>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  )
                )}
            </div>

            <div className="bmi_calc_related_reads">
              <div className="related_reads_wrapper">
                <span className="related_reads_heading">
                  <Text
                    field={tools?.first[activeTabIndex].relatedReadsFolder[0]?.heading}
                    editable
                  />
                </span>
                <div className="swiper nav_related_reads_slider nav_slider_Wrapper swiper-initialized swiper-horizontal swiper-backface-hidden ">
                  <div className="swiper-wrapper">
                    {tools?.first[activeTabIndex]?.relatedReadsFolder[0]?.relatedReads?.map(
                      (relatedRead) => {
                        return (
                          <div key={relatedRead?.id} className="swiper-slide">
                            <div className="related_reads_slide_wrapper ">
                              <picture>
                                <source srcSet={relatedRead?.bgImage?.src} type="image/webp" />
                                <Image
                                  field={relatedRead?.bgImage}
                                  alt="img"
                                  className="w-100"
                                  loading="lazy"
                                />
                              </picture>
                              <div className="nav_slider_content">
                                <a
                                  href={relatedRead?.ctaLink?.url}
                                  target={relatedRead?.ctaLink?.target}
                                >
                                  <span className="nav-slide-heading">
                                    {relatedRead?.heading?.value}
                                  </span>
                                </a>
                                <p>{relatedRead?.subHeading?.value}</p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="swiper-pagination nav_related_reads_pagination"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Tools);
