import React, { useEffect, useState } from 'react';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const MegaMenuHolder = (props) => {
  const [activeSecondaryTab, setActiveSecondaryTab] = useState('');
  const [initialRender, setInitialRender] = useState(true);
  const [activeCrossSell, setActiveCrossSell] = useState([]);
  const { activeTab, navigationResult } = props;

  const [activeThirdTab, setActiveThirdTab] = useState('');

  const changeActiveSecondaryTab = (newTab) => {
    if (initialRender) {
      setInitialRender(false);
    }
    setActiveThirdTab('');
    setActiveSecondaryTab(newTab);
    const crossSellData =
      navigationResult?.primary
        .find((mainNav) => mainNav.tabs.some((tab) => tab.id === newTab))
        ?.tabs.find((tab) => tab.id === newTab)?.crossSell || [];
    setActiveCrossSell(crossSellData);
  };

  const changeActiveThirdTab = (newTab) => {
    setActiveThirdTab(newTab);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const initializeSwiper = () => {
        const swiper1 = new Swiper('.nav_related_reads_slider', {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        });

        const swiper2 = new Swiper('.sip_calc_nav_slider', {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        });
      };

      if (document.readyState === 'complete') {
        // Document is already loaded, initialize Swiper immediately
        initializeSwiper();
      } else {
        // Document is not yet loaded, set up an event listener for the load event
        window.addEventListener('load', initializeSwiper);
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('load', initializeSwiper);
        };
      }
    }
  }, []);

  useEffect(() => {
    if (navigationResult?.primary) {
      const initialTab = navigationResult.primary.find((mainNav) => mainNav.id === activeTab)
        ?.tabs[0];
      if (initialTab) {
        setActiveSecondaryTab(initialTab.id);
        setActiveCrossSell(initialTab.crossSell || []);
      }
    }
  }, [activeTab, navigationResult?.primary]);

  return (
    <>
      {navigationResult?.primary?.map((mainNav) => {
        return (
          <div
            className={`${mainNav?.CssClassName?.value}_megamenu_wrapper ${props?.navStickyClass} ${
              activeTab === mainNav.id ? 'megamenu_open' : ''
            }`}
            key={mainNav.id}
            id={`${mainNav.CssClassName.value}_wrapper_id`}
          >
            <div className={`${mainNav?.CssClassName?.value}_megamenu_container`}>
              <div className={`${mainNav?.CssClassName?.value}_options_wrapper`}>
                <div className={`${mainNav?.CssClassName?.value}_lists_wrapper`}>
                  <ul className={`${mainNav?.CssClassName?.value}_lists`}>
                    {mainNav?.tabs?.map((tab, index) => {
                      const isFirstTab = initialRender && index === 0;
                      const isFirstTabActive = isFirstTab || activeSecondaryTab === tab?.id;
                      return (
                        <li
                          key={tab?.id}
                          id={tab?.CssId.value}
                          className={`${isFirstTabActive ? 'list_hovered' : ''}`}
                          onMouseEnter={() => {
                            changeActiveSecondaryTab(tab?.id);
                          }}
                        >
                          {tab?.menuLink?.url === null ||
                          tab?.menuLink?.url === 'http://javascript:void(0)' ? (
                            <a id="menuTitle">
                              <Text field={tab?.menuTitle} editable />
                              <em className="right_arrow"></em>
                            </a>
                          ) : (
                            <a
                              id="menuTitle"
                              href={tab?.menuLink?.url}
                              target={tab?.menuLink?.target}
                            >
                              <Text field={tab?.menuTitle} editable />
                              <em className="right_arrow"></em>
                            </a>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                  {activeCrossSell?.length > 0 && (
                    <div className={`${mainNav?.CssClassName?.value}_info`}>
                      <span className="heading-mfy">
                        <RichText field={activeCrossSell[0]?.heading} editable />
                        <RichText field={activeCrossSell[0]?.subheading} editable />
                      </span>

                      <div className="know-more-btn nav_knw_btn_wrapper">
                        {activeCrossSell[0]?.knowMoreButton?.url && (
                          <a
                            className="know_more_about-us outline_btn"
                            href={activeCrossSell[0]?.knowMoreButton?.url}
                            target={activeCrossSell[0]?.knowMoreButton?.target}
                          >
                            <Text field={activeCrossSell[0]?.knowMoreLabel} editable />
                          </a>
                        )}
                        {activeCrossSell[0]?.applyNowButton?.url && (
                          <a
                            className="know_more_about-us solid_btn"
                            href={activeCrossSell[0]?.applyNowButton?.url}
                            target={activeCrossSell[0]?.applyNowButton?.target}
                          >
                            <Text field={activeCrossSell[0]?.applyNowLabel} editable />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {mainNav?.tabs.map((tab, index) => {
                  return (
                    <div
                      className={`${mainNav.CssClassName?.value}_plans_Wrapper ${
                        activeSecondaryTab === tab.id ||
                        (activeSecondaryTab === '' && mainNav?.tabs[0].id === tab.id)
                          ? 'list_hovered'
                          : 'd-none'
                      }`}
                      key={index}
                    >
                      <div className="terms_list_wrapper">
                        {tab?.secondary.length > 0 && (
                          <div className="terms_list_grid">
                            {tab?.secondary?.map((menuItem, index) => {
                              return (
                                <a
                                  className="terms_wrapper"
                                  key={index}
                                  href={menuItem?.menuLink?.url}
                                  target={menuItem?.menuLink?.target}
                                >
                                  <Image
                                    field={menuItem?.icon_03d0a54087195a4da66fc707ac0c4572}
                                    loading="lazy"
                                    editable
                                    alt="img"
                                  />
                                  <span className="term-card-heading">
                                    <Text field={menuItem?.menuTitle} editable />
                                  </span>
                                  <Text field={menuItem?.menuSubTitle} tag="p" editable />
                                </a>
                              );
                            })}

                            <div
                              className={`quick_links_wrapper" ${
                                Array.isArray(tab?.quickLinksFolder) &&
                                tab.quickLinksFolder.length > 0
                                  ? 'block'
                                  : 'hide'
                              }`}
                            >
                              <span className="quick-link-heading">
                                <Text field={tab?.quickLinksFolder?.heading} editable />
                              </span>
                              <div className="wrapper-row">
                                {tab?.quickLinksFolder?.quickLinks?.map((quickLink) => {
                                  return (
                                    <div className="row-col" key={quickLink?.label?.value}>
                                      <div className="quick_links_grid">
                                        <ul>
                                          <li>
                                            <a
                                              href={quickLink?.link?.url}
                                              target={quickLink?.link?.target}
                                            >
                                              {quickLink?.label?.value}{' '}
                                              <em className="chevron_right"></em>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                        {tab?.menuTabs.length > 0 && (
                          <>
                            {tab?.menuTabs.map((menu, index) => (
                              <span key={index}>
                                <span className="wrapper_title_text">
                                  <Text field={menu?.menuTitle} editable />
                                </span>
                                <div className="terms_list_grid">
                                  {menu?.secondary?.map((menuItem, index) => {
                                    return (
                                      <a
                                        className="terms_wrapper"
                                        key={index}
                                        href={menuItem?.menuLink?.url}
                                        target={menuItem?.menuLink?.target}
                                      >
                                        <Image
                                          field={menuItem?.icon_03d0a54087195a4da66fc707ac0c4572}
                                          loading="lazy"
                                          editable
                                          alt="img"
                                        />
                                        <span className="term-card-heading">
                                          <Text field={menuItem?.menuTitle} editable />
                                        </span>
                                        <p>
                                          <Text field={menuItem?.menuSubTitle} editable />
                                        </p>
                                      </a>
                                    );
                                  })}
                                </div>{' '}
                              </span>
                            ))}
                          </>
                        )}
                        <div className="quick_links_wrapper">
                          <span
                            className={`quick-link-heading ${
                              tab?.quickLinksFolder.length > 0 ? 'block' : 'hide'
                            }`}
                          >
                            <Text field={tab?.quickLinksFolder[index]?.heading} editable />
                          </span>
                          <div className="wrapper-row">
                            <div className="row-col">
                              <div
                                className={`quick_links_grid ${
                                  tab?.quickLinksFolder.length > 0 ? 'block' : 'hide'
                                }`}
                              >
                                <ul>
                                  {tab?.quickLinksFolder[index]?.quickLinks?.map((quickLink) => {
                                    return (
                                      <li key={quickLink?.label?.value}>
                                        <a
                                          href={quickLink?.link?.url}
                                          target={quickLink?.link?.target}
                                        >
                                          <Text field={quickLink?.label} editable />
                                          <em className="chevron_right"></em>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            {mainNav?.tabs[index]?.free_credit_score?.length > 0 && (
                              <div className="recoll">
                                <div className="fcs-vs">
                                  <div
                                    className="bmi_cal_wrapper"
                                    style={{
                                      background: `url(${mainNav?.tabs[index]?.free_credit_score[0]?.backgroundImage?.src})`,
                                      backgroundSize: 'cover',
                                    }}
                                  >
                                    <div className="bmi_ico">
                                      <Image
                                        field={
                                          mainNav?.tabs[index]?.free_credit_score[0]?.calculatorIcon
                                        }
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="bmi_content">
                                      <div className="quick-link-heading">
                                        <Text
                                          field={
                                            mainNav?.tabs[index]?.free_credit_score[0]?.heading
                                          }
                                          editable
                                        />
                                      </div>
                                      <p>
                                        <Text
                                          field={
                                            mainNav?.tabs[index]?.free_credit_score[0]?.subheading
                                          }
                                          editable
                                        />
                                      </p>
                                      <a
                                        href={mainNav?.tabs[index]?.free_credit_score[0]?.link?.url}
                                        target={mainNav?.tabs[index]?.free_credit_score[0]?.link?.target}
                                      >
                                        {
                                          mainNav?.tabs[index]?.free_credit_score[0]?.linkLabel
                                            ?.value
                                        }
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* )} */}
                      </div>

                      <div className="bmi_calc_related_reads">
                        {tab?.calculatorLinks.length > 0 && (
                          <div className="bmi_cal_slider_wrapper">
                            <div className="swiper sip_calc_nav_slider">
                              <div className="swiper-wrapper">
                                {tab?.calculatorLinks[0]?.children.map((calc, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="swiper-slide swiper-slide-active"
                                      role="group"
                                      aria-label={`${index + 1} / 3`}
                                      style={{
                                        background: `url(${calc?.backgroundImage?.src})`,
                                        backgroundSize: 'cover',
                                        width: '275px',
                                        marginRight: '10px',
                                      }}
                                    >
                                      <div className="bmi_cal_wrapper">
                                        <div className="bmi_ico">
                                          <Image field={calc?.calculatorIcon} loading="lazy" />
                                        </div>
                                        <div className="bmi_content">
                                          <span className="bmi_content_heading">
                                            {calc?.heading?.value}
                                          </span>
                                          <p>{calc?.subheading?.value}</p>
                                          <a
                                            href={calc?.link?.url}
                                            target={calc?.link?.target}
                                          >
                                            {calc?.linkLabel?.value}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="swiper-pagination sip_calc_pagination"></div>
                            </div>
                          </div>
                        )}
                        <div className="related_reads_wrapper">
                          <span className="related_reads_heading">
                            <Text field={tab?.relatedReadsFolder[0]?.heading} editable />
                          </span>
                          <div className="swiper nav_related_reads_slider nav_slider_Wrapper swiper-initialized swiper-horizontal swiper-backface-hidden ">
                            <div className="swiper-wrapper">
                              {tab?.relatedReadsFolder[0]?.relatedReads?.map((relatedRead) => {
                                return (
                                  <div key={relatedRead?.id} className="swiper-slide">
                                    <div className="related_reads_slide_wrapper ">
                                      <picture>
                                        <source
                                          srcSet={relatedRead?.bgImage?.src}
                                          type="image/webp"
                                        />
                                        <Image
                                          field={relatedRead?.bgImage}
                                          className="w-100"
                                          loading="lazy"
                                          alt="img"
                                        />
                                      </picture>
                                      <div className="nav_slider_content">
                                        <a
                                          href={relatedRead?.ctaLink?.url}
                                          target={relatedRead?.ctaLink?.target}
                                        >
                                          <span className="nav-slide-heading">
                                            {relatedRead?.heading?.value}
                                          </span>
                                        </a>
                                        <p>{relatedRead?.subHeading?.value}</p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="swiper-pagination nav_related_reads_pagination"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MegaMenuHolder;
