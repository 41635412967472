import React, { useState, useEffect } from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const MegaMenuQuickServices = ({
  isShow,
  onQuickServiceLoad,
  navStickyClass,
  quickServicesData,
}) => {
  // const isShow = props?.isShow;
  const quickServices = quickServicesData?.targetItem?.children;
  // const onQuickServiceLoad = props?.onQuickServiceLoad;

  useEffect(() => {
    if (quickServices) {
      onQuickServiceLoad(quickServices);
    }
  }, [quickServices]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div
      className={`quckserives_megamenu_wrapper box-wrapper ${navStickyClass} ${
        isShow ? 'megamenu_open' : ''
      }`}
      id="quick_services_wrapper_id"
    >
      <div className="loan_megamenu_container">
        <div className="loan_options_wrapper">
          <div className="loan_lists_wrapper">
            <div className="loan_plans_Wrapper">
              <div className="terms_list_wrapper">
                <div className="terms_list_grid">
                  {quickServices?.map((item) => (
                    <div className="card border rounded-3 p-4" key={item.id}>
                      <span className="term-card-heading">
                        <Image
                          field={item.menuIcon}
                          className="quick_service_icon mr-3"
                          alt="img"
                          loading="lazy"
                        />
                        {item.menuTitle?.value}
                      </span>
                      <ul className="term_list-mega_menu">
                        {item.nav_links?.map((link, index) => (
                          <li key={index}>
                            <a
                              id="quickServicesItem"
                              href={link.link?.url}
                              target={link.link?.target}
                              className="cursor-pointer"
                            >
                              {link.label?.value}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaMenuQuickServices;
